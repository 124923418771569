export class EventsIds {
  static readonly IMPRESSIONS = 1;
  static readonly CLICK = 2;
  static readonly SENT_BIDRESPONSE = 51;
  static readonly FORWARDED_BIDREQUEST = 72;
  static readonly MONEY_SPENT = 92;
  static readonly CPM_SPENT = 102;
  static readonly SSP_BID_PLACED = 106;
  static readonly INCOMING_BIDREQUEST = 107;
  static readonly BID_RENDER = 115;
  static readonly WEBSITE_FAIL = 208;
  static readonly SSP_DEAL_REQUEST = 241;
  static readonly TRACKED_CLICK = 257;
  static readonly CONVERSIONS = 275;
  static readonly ATTRIBUTED_CONVERSIONS = 276;
  static readonly TOTAL_COST = 308;
  static readonly COST_TO_CLIENT = 394;
  static readonly EARNINGS = 416;
  static readonly APP_TRAFFIC_FAIL = 417;
  static readonly ONESWITCH_FAIL = 421;
  static readonly INCOMING_DEAL_REQUEST = 439;
  static readonly PUBLISHER_TECH_FEE = 441;
  static readonly NET_EARNINGS = 442;
  static readonly ADSTXT_FAIL = 443;
  static readonly DOLLAR_MONEY_SPENT = 451;
  static readonly HB_BIDREQUEST = 496;
  static readonly HB_BIDRESPONSE = 499;
  static readonly HB_EARNINGS = 513;
  static readonly CURRENCY_EARNINGS = 523;
  static readonly CURRENCY_NET_EARNINGS = 524; // Deprecated
  static readonly CURRENCY_TECH_FEE = 525;
  static readonly NET_REVENUE_PREVIEW = 544;
  static readonly GROSS_REVENUE_PREVIEW = 544;
  static readonly EUR_PUBLISHER_TECH_FEE = 546;
  static readonly HB_WRAPPER_IMPRESSION = 555;
  static readonly CURRENCY_NET_REVENUE = 556;
  static readonly CURRENCY_GROSS_REVENUE = 557;
  static readonly CURRENCY_PUBLISHER_TECH_FEE = 558;
  static readonly DN = 565;
  static readonly PAID_IMPRESSION = 580;
  static readonly EARNINGS_VIRTUAL = 599;
  static readonly REJECTED_INCOMING_BIDREQUEST = 602;
  static readonly GROSS_PROFIT_VIRTUAL = 610;
  static readonly REVENUE_SHARE = 624;
  static readonly CURRENCY_REVENUE_SHARE = 631;
  static readonly CURRENCY_DEAL_SPEND = 634;
  static readonly REJECTED_INCOMING_ADSPACE_OPPORTUNITY = 640;
  static readonly RECEIVED_DEAL_RESPONSE = 662;
  static readonly TARGET = 999;
}
