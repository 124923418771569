import {SelectItem} from 'primeng/api';
import {EventsIds} from '../../__helpers/entities/EventsIds';
import {MetricsIds} from '../../__helpers/entities/MetricsIds';

export class RepoConstants {
  public static readonly DEAL_COLUMNS: SelectItem[] = [
    {value: EventsIds.TOTAL_COST, label: 'EVENT'},
    {value: EventsIds.GROSS_PROFIT_VIRTUAL, label: 'EVENT'},
    {value: EventsIds.DN, label: 'EVENT'},
    {value: MetricsIds.VTR, label: 'METRIC'},
    {value: MetricsIds.CTR, label: 'METRIC'},
    {value: MetricsIds.VIEWABILITY, label: 'METRIC'},
  ];

  public static readonly QUERY_REPORT_SQL = 0;
  // public static readonly QUERY_REPORT_IMPALA = 1;
  // public static readonly QUERY_REPORT_KYLIN = 2;
  public static readonly QUERY_REPORT_CLICKHOUSE = 4;
  public static readonly QUERY_REPORT_ATHENA = 5;
}
